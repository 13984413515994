<template>
  <div class="home">
    <div class="swiper">
      <el-carousel height="100vh">
        <el-carousel-item>
          <div class="about-e">
            <div class="swiper-content">
              <div>ONE WORLD</div>
              <div>ONE VILLAGE</div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="about-chat">
            <div class="swiper-content">
              <p>先锋云信</p>
              <p>让沟通更高效</p>
              <div>提供即时通讯、视频会议以及多种具备特色的功能助力组织高效沟通</div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="about-xf">
            <div class="swiper-content">
              <p>先锋云信</p>
              <p>安全可靠的协作平台</p>
              <div>全面支持信创标准，自主知识产权，融合即时通讯、实时协作于一体，专注私有化部署，全平台覆盖，完美支持操作系统，提供即时通讯、视频会议、应用商城等功能和丰富的API接口。</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="advantage">
      <h1>产品优势</h1>
      <div class="ad-detail">
        <div class="detail">
          <img src="/assets/traceless.png" loading="lazy">
          <h3>复杂消息格式</h3>
          <div class="content">支持复杂文本格式，市场中独有的特色功能助力提升工作效率！</div>
          <el-button class="button" type="primary">了解更多</el-button>
        </div>
        <div class="detail">
          <img src="/assets/over-group.png" loading="lazy">
          <h3>跨组织沟通</h3>
          <div class="content">先锋云信支持多企业同平台交流，打通企业间交流壁垒。</div>
          <el-button class="button" type="primary">了解更多</el-button>
        </div>
        <div class="detail">
          <img src="/assets/traceless.png" loading="lazy">
          <h3>无痕消息</h3>
          <div class="content">设置无痕消息，涉及项目私密性消息无需担心泄露。</div>
          <el-button class="button" type="primary">了解更多</el-button>
        </div>
        <div class="detail">
          <img src="/assets/AI.jpg" loading="lazy">
          <h3>对接AI大模型</h3>
          <div class="content">先锋云信对接多种AI大模型，提供强大的搜索引擎助力办公效率。</div>
          <el-button class="button" type="primary">了解更多</el-button>
        </div>
      </div>
    </div>

    <div class="more-adv">
      <div class="title-m">
        <el-tabs v-model="clickIndex">
          <el-tab-pane label="助力组织高效沟通"  :name="1">
          </el-tab-pane>
          <el-tab-pane label="全方位保障信息安全" :name="2"></el-tab-pane>
          <el-tab-pane label="产品迭代升级"      :name="3"></el-tab-pane>
        </el-tabs>
      </div>

      <div v-if="clickIndex == 1" class="more-detail">
        <div class="detail-c">
          <div class="title-sub">助力组织高效沟通</div>
          <div class="title-o">
            <div class="title-c">支持复杂消息格式</div>
            <div class="title-mc">轻松地编辑文本和其他文件，传递信息更清晰高效</div>
          </div>
          <div class="title-o">
            <div class="title-c">支持跨组织交流</div>
            <div class="title-mc">支持多企业同平台交流，打通企业间交流壁垒</div>
          </div>

          <div class="title-o">
            <div class="title-c">支持点对点文件直传</div>
            <div class="title-mc">局域网下高效传输超大文件</div>
          </div>

          <div class="title-o">
            <div class="title-c">支持复杂类型格式消息</div>
            <div class="title-mc">轻松地编辑文本和其他文件，传递信息更清晰高效</div>
          </div>
        </div>
        <img src="/assets/commu.jpg" loading="lazy">
      </div>

      <div v-if="clickIndex == 2" class="more-detail">
        <div class="detail-c">
          <div class="title-sub">全方位保障信息安全</div>
          <div class="title-o">
            <div class="title-c">数据安全</div>
            <div class="title-mc">数据经过量子加密，传统方式无法破解</div>
          </div>
          <div class="title-o">
            <div class="title-c">私有部署</div>
            <div class="title-mc">支持私有部署，可以拥有绝对的使用信息安全</div>
          </div>

          <div class="title-o">
            <div class="title-c">系统安全</div>
            <div class="title-mc">采用高可用的系统，实时监控系统运行情况</div>
          </div>

          <div class="title-o">
            <div class="title-c">代码加密</div>
            <div class="title-mc">支持多种加密方式，保证安全可靠</div>
          </div>
        </div>
        <img src="/assets/quantum.jpg" style="width: 50%" loading="lazy">
      </div>

      <div v-if="clickIndex == 3" class="more-detail">
        <div class="detail-c">
          <div class="title-sub">产品迭代升级</div>
          <div class="title-o">
            <div class="title-c">技术架构升级</div>
            <div class="title-mc">采用主流架构，集成多种云服务功能</div>
          </div>
          <div class="title-o">
            <div class="title-c">用户体验升级</div>
            <div class="title-mc">强调用户用户体验。包括界面设计的优化、流程的简化等</div>
          </div>

          <div class="title-o">
            <div class="title-c">完备技术支持</div>
            <div class="title-mc">提供完备的产品技术支持，相关问题实时解答</div>
          </div>

          <div class="title-o">
            <div class="title-c">产品更新</div>
            <div class="title-mc">持续迭代产品优化升级，用户及时获取最新功能</div>
          </div>
        </div>
        <img src="/assets/commu.jpg" loading="lazy">
      </div>

    </div>

    <div class="os-support">
      <div class="title">适配国产系统助力完善信创生态体系</div>
      <div class="desc">全面支持银河麒麟、统信UOS、中科方德、深度等国产操作系统，充分助力政府企业单位打造自主可控、安全高效的即时通讯及协同办公平台。</div>
      <div class="os-img-container">
        <div class="os-img">
        </div>
      </div>
    </div>

    <div class="deploy">
      <div class="title">灵活的部署方式</div>
      <div class="deploy-detail">
        <div class="deploy-card">
          <div class="deploy-card-t">整套通讯系统</div>
          <div class="deploy-card-t">私有部署</div>
          <div class="deploy-card-d">通讯系统支持客户自己私有部署</div>
          <div class="deploy-card-d">可拥有绝对的使用信息安全</div>
          <div class="deploy-card-d">可拥有高效流畅度的体验</div>
        </div>
        <div class="deploy-card">
          <div class="deploy-card-t">应用服务器</div>
          <div class="deploy-card-t">私有部署</div>
          <div class="deploy-card-d">通讯系统支持客户自己私有部署</div>
          <div class="deploy-card-d">可拥有绝对的使用信息安全</div>
          <div class="deploy-card-d">可拥有高效流畅度的体验</div>
        </div>
        <div class="deploy-card">
          <div class="advise-c">
            推荐
          </div>
          <div class="deploy-card-t">
            先锋云信
          </div>
          <div class="deploy-card-t">使用公有云服务</div>
          <div class="deploy-card-d">公有云服务可减少企业管理费用</div>
          <div class="deploy-card-d">同时支持部署使用自己的私有插件</div>
          <div class="deploy-card-d">可以拥有高效流畅度的体验</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, ref } from 'vue';

let clickIndex = ref<number>(1);
</script>
<style lang="scss" scoped>
$img-height: 100%;

h1 {
  margin-bottom: 0px;
}
.home {
  width: 100%;

  .swiper {

    .about-e {
      height: 100vh;
      width: 100%;
      position: relative;
      color: white;
      background-image: url('/public/assets/homebgEarth.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .swiper-content {
        text-align: start;
        position: absolute;
        left: 8%;
        top: 30%;
        letter-spacing: 5px;

        &>div {
          font-weight: bold;
          font-size: 3rem;
        }
      }
    }

    .about-chat {
      height: $img-height;
      width: 100%;
      position: relative;
      color: white;
      background-image: url('/public/assets/blue-earth.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .swiper-content {
        text-align: start;
        position: absolute;
        left: 8%;
        top: 25%;
        letter-spacing: 5px;

        &>p {
          font-weight: bold;
          font-size: 3em;
          margin-top: 0;
        }

        :nth-child(1) {
          margin-bottom: 0;
        }
      }
    }

    .about-xf {
      height: $img-height;
      width: 100%;
      position: relative;
      color: white;
      background-image: url('/public/assets/homebgBlue.jpg');
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .swiper-content {
        text-align: start;
        position: absolute;
        left: 8%;
        top: 25%;
        letter-spacing: 5px;

        &>p {
          font-weight: bold;
          font-size: 3rem;
          margin-top: 0;
        }

        &>div {
          width: 40%;
          line-height: 2rem;
        }

        :nth-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }

  .advantage {
    height: 80vh;
    min-height: 625px;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 3rem;
    }

    .ad-detail {
      // height: calc(80vh * 0.82);
      height: 100%;
      flex: 1;
      margin-top: 1%;
      padding: 0 3% 0 3%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .detail {
        width: calc(92% / 4);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 1.5rem;
        }

        img {
          width: 90%;
        }

        &>div {
          text-align: start;
        }

        &:hover {
          box-shadow: 0 0 5px 0 #007FFF;
          border-radius: 5px;
        }

        .button {
          margin-top: 5%;
          margin-bottom: 5%;
          width: 50%; 
          height: 9%;
          background-color: #0079FF;
        }

        .content {
          width: 80%;
          height: 15%;
          font-size: 1.2rem;
        }
      }
    }
  }

  .more-adv {
    height: 80vh;
    min-height: 610px;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 100%;
    box-sizing: border-box;
    
    .title-m {
      font-size: 1.75rem;
      margin-top: 5%;
      margin-bottom: 5%;
      width: 100%;

      .el-tabs {
        --el-tabs-header-height: 60px;

        :deep(.el-tabs__header) {

          .el-tabs__nav-wrap {
            &:after {
              height: 2px;
            }
          }

          .el-tabs__item {
            font-weight: 600;
            color: #666666;
            font-size: 1.75rem;
            margin-left: 10vw;
            margin-right: 10vw;
          } 
        }
      }
    }

    .more-detail {
      height: 80%;
      width: 100%;
      box-sizing: border-box;
      padding-left: 20%;
      padding-right: 3%;
      display: flex;
      flex-direction: row;

      .detail-c {
        width: 40%;
        text-align: left;

        .title-sub {
          font-size: 2.5rem;
          color: #333333;
          margin-bottom: 70px;
          font-weight: bold;
        }

        .title-o {
          margin-top: 20px;
          margin-bottom: 40px;
        }

        .title-c {
          font-weight: 500;
          font-size: 1.25rem;
          margin-bottom: 16px;
          color: #222222;
          position: relative;
          margin-left: 5%;

          &::before {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            top: 12px;
            background-color: #007FFF;
            position: absolute;
            left: -24px;
          }
        }

        .title-mc {
          margin-top: 10px;
          font-size: 1rem;
          margin-left: 28px;
          color: #666666;
        }
      }

      img {
        width: 67%;
        align-self: center;
      }
    }
  }

  .os-support {
    margin-top: 4%;

    .desc {
      color: #666666;
      margin: 3% 0 3% 0;
      font-size: 1rem;
    }

    .os-img {
      width: 70vw;
      height: 70vh;
      background-image: url('/public/assets/os.jpg');
      background-size: 100% 100%;
      background-color: white;
    }

    .os-img-container {
      display: flex;
      justify-content: center;
    }
  }

  .deploy {
    width: 100%;
    height: 500px;
    margin-top: 3%;
    margin-bottom: 5%;

    .deploy-detail {
      padding: 5% 5% 5px 5%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .deploy-card {
        width: 30%;
        height: 80%;
        border-radius: 10px;
        box-shadow: 1px 1px 10px 0px #3D61F4;
        transition: transform .2s;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .deploy-card-t {
          font-size: 2.5rem;
          width: 280px;
          margin-bottom: 20px;
          position: relative;
        }

        .advise-c {
          font-size: 1rem;
          width: 100px;
          position: absolute;
          top: 28px;
          left: 40px;
          border: 1px #26CB71 solid;
          border-radius: 20px;
        }

        .deploy-card-d {
          font-size: 1.5rem;
          width: 380px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .title {
    font-size: 2.625rem;
    font-weight: bold;
  }
}
</style>
