import axios from 'axios'

const request = axios.create({
  baseURL: getBaseUrl(),
  timeout: 5000, //超时的时间
})

export function getBaseUrl(): string {
  let baseURL = ''
  if(process.env.VUE_APP_ENV == 'dev') {
    baseURL = '';
  } else {
    baseURL = process.env.VUE_APP_NGINX_BASE_API
  }

  return baseURL;
}

export function getApplyInformation(row : any) {
  return request({
    url: '/apply/add',
    method: 'POST',
    data: row
  })
}

export function getCaptcha(phone : number) {
  return request({
    url: '/message/sendSmsCode',
    method: 'POST',
    params: {phone},
    data:{
      client_id     : "village",
      client_secret : "123456"
    }
  })
}

export function createComments(from : any) {
  return request({
    url: '/comments/createComments',
    method: 'POST',
    data: from
  })
}
