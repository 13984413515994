<template>
  <div class="title-container" :class="{'scroll' : topSrcoll}">
    <div class="title-bar">
      <div class="company">
        <div class="logo">
          <img src="/assets/logo.png">
        </div>
        <div class="title-c">
          <span class="company-name">同村科技</span>
          <span>SameVillage</span>
        </div>
        
      </div>
      <div class="menu">
        <el-menu
          router
          :default-active="useRoute().path"
          mode="horizontal"
          text-color="#000"
          active-text-color="#086bf6"
          style="height: 100%;"
          :popper-offset="0"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-sub-menu popper-class="poper">
            <template #title>产品</template>
            <el-menu-item index="/aboutXf">先锋云信</el-menu-item>
            <el-menu-item index="/aboutLiveMeeting">视频会议</el-menu-item>
            <el-menu-item index="/aboutQuantum">量子加密</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="/try">试用</el-menu-item>
          <el-menu-item index="/price">价格</el-menu-item>
          <el-menu-item index="/solution">解决方案</el-menu-item>
          <el-menu-item index="/download">下载中心</el-menu-item>
          <el-menu-item index="/contact">联系我们</el-menu-item>
        </el-menu>
      </div>
      <div class="app-store">
        <a target="_blank" href="http://xf.samevillage.com:8091/login">应用商城</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

let topSrcoll = ref<boolean>(false);
let scrollTop = ref<number>(0);

onMounted(() => {
  window.addEventListener('scroll', onWindowSrcoll)
})

watch(scrollTop, (newValue, oldValue) => {
  if (newValue > 100) {
    if (newValue > oldValue) {
      topSrcoll.value = true
    } else {
      topSrcoll.value = false
    }
  }
})

function onWindowSrcoll() {
  scrollTop.value = scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
}

</script>
<style lang="scss" scoped>
$title-font-size: 1.125rem;

.scroll {
  transform: translateY(-100px);
}

.title-container {
  width: 100%;
  min-width: 1024px;
  background-color: white;
  top: 0px;
  position: fixed;
  z-index: 999;
  transition: all 0.5s;
}

.title-bar {
  color: #333333;
  width: 100%;
  height: 78px;
  padding-left: 3%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;

  .company {
    margin-right: 5%;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;

    .logo {
      box-sizing: border-box;
      width: 35%;
      height: 100%;
      max-width: 80px;
      max-height: 80px;
      padding: 5px 5px 5px 5px;

      :nth-child(1) {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }

    .title-c {
      // width: 50%;
      flex: 1;
      height: 100%;
      margin-left: 2%;
      display: flex;
      text-align: left;
      flex-direction: column;
      justify-content: center;

      .company-name {
        font-size: 1.5rem;
        letter-spacing: 6px;
      }

      :nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 3px;
      }
    }
  }

  .menu {
    width: 100%;
    flex: 1;
    margin-right: 5%;

    .el-menu {
      border-bottom: none;

      .el-menu-item {
        line-height: 24px;
        width: 8vw;
        min-width: 60px;
        font-size: $title-font-size;
        font-weight: 600;
      }

      .el-sub-menu {

        :deep(.el-sub-menu__title) {
          font-weight: 600;
          font-size: $title-font-size;
        }
      }
    }
  }

  .app-store {
    white-space: nowrap;
    font-size: 1rem;
    margin-right: 5%;
    display: flex;

    a {
      text-decoration: none;
      color: inherit;
    }

    :first-child {
      align-self: center;
      text-decoration: underline #004aff solid;
      text-underline-position: under;
    }
  }
}
</style>